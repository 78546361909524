// Generated by Framer (e8e43ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/uwikgcwlh";
import * as localizedValues from "./LnhM_RzEl-0.js";

const enabledGestures = {Eyr1OGZb5: {hover: true}};

const cycleOrder = ["Eyr1OGZb5"];

const serializationHash = "framer-2mHdC"

const variantClassNames = {Eyr1OGZb5: "framer-v-8nswnn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {bCVeFfDXK: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({buttonName, height, id, link, width, ...props}) => { return {...props, ds0wpE_Mv: buttonName ?? props.ds0wpE_Mv ?? "Button", lGvs6zrgy: link ?? props.lGvs6zrgy} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;buttonName?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lGvs6zrgy, ds0wpE_Mv, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Eyr1OGZb5", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={lGvs6zrgy} openInNewTab={false}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8nswnn", className, classNames)} framer-1ojzen2`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Eyr1OGZb5"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(247, 163, 26)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} variants={{"Eyr1OGZb5-hover": {backgroundColor: "rgba(247, 163, 26, 0.8)"}}} {...addPropertyOverrides({"Eyr1OGZb5-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-zwgxa2"} data-styles-preset={"uwikgcwlh"}>Button</motion.p></React.Fragment>} className={"framer-ulkw3o"} data-framer-name={"Aktuálne Projekty"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"XAyvwcQTI"} style={{"--framer-paragraph-spacing": "0px"}} text={ds0wpE_Mv} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2mHdC.framer-1ojzen2, .framer-2mHdC .framer-1ojzen2 { display: block; }", ".framer-2mHdC.framer-8nswnn { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 12px 20px 12px 20px; position: relative; text-decoration: none; width: min-content; }", ".framer-2mHdC .framer-ulkw3o { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2mHdC.framer-8nswnn { gap: 0px; } .framer-2mHdC.framer-8nswnn > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-2mHdC.framer-8nswnn > :first-child { margin-left: 0px; } .framer-2mHdC.framer-8nswnn > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 93
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"L7Ft5Zcjo":{"layout":["auto","auto"]}}}
 * @framerVariables {"lGvs6zrgy":"link","ds0wpE_Mv":"buttonName"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLnhM_RzEl: React.ComponentType<Props> = withCSS(Component, css, "framer-2mHdC") as typeof Component;
export default FramerLnhM_RzEl;

FramerLnhM_RzEl.displayName = "Button";

FramerLnhM_RzEl.defaultProps = {height: 49, width: 93};

addPropertyControls(FramerLnhM_RzEl, {lGvs6zrgy: {title: "Link", type: ControlType.Link}, ds0wpE_Mv: {defaultValue: "Button", displayTextArea: false, title: "Button Name", type: ControlType.String}} as any)

addFonts(FramerLnhM_RzEl, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})